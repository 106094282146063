<template>
  <div>
    <!-- <div id="space-box" :style="{ height: dynamicHeight + 'px' }"></div> -->
    <!-- <div id="main-box" v-observe-visibility="animateDiv"> -->
    <div id="main-box">
      <div id="portfolio-one-container" class="container">
        <div
          v-for="(item, index) in albumSection"
          :key="index"
          id="portfolio-one-row-container"
          class="row"
        >
          <div
            v-for="(album, indexAlbum) in item"
            :key="indexAlbum"
            class="col-lg-2 col-md-3 col-sm-12"
          >
            <div class="gallery-section" @click="handleClickLink(album.link)">
              <img :src="require(`../assets/${album.imageUrl}.png`)" />
              <h3>{{ album.title }}</h3>
              <p>{{ album.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div id="portfolio-two-container" class="container">
        <div
          v-for="(item, index) in performanceSection"
          :key="index"
          id="portfolio-two-row-container"
          class="row"
        >
          <div
            v-for="(performance, indexPerformance) in item"
            :key="indexPerformance"
            class="col-lg-6 col-md-6 col-sm-12"
          >
            <div
              class="gallery-section"
              @click="handleClickLink(performance.link)"
            >
              <img :src="require(`../assets/${performance.imageUrl}.png`)" />
              <h3>{{ performance.title }}</h3>
              <p>{{ performance.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div id="background-text-box">
        <div
          v-if="!isSmallScreen"
          id="contact-box"
          class="d-flex justify-content-between align-items-center"
        >
          <p id="contact-text-left">GET IN TOUCH THROUGH</p>
          <p id="contact-text-left">
            <a id="instagram-link" href="mailto:aidan.rahman@gmail.com"
              >AIDAN.RAHMAN@GMAIL.COM</a
            >
          </p>
          <p id="contact-text-right">
            <a
              id="instagram-link"
              href="https://www.instagram.com/aidan.rahman/"
              target="_blank"
              >@AIDAN.RAHMAN</a
            >
          </p>
        </div>
        <div v-if="isSmallScreen" id="contact-box">
          <p id="contact-text-left">GET IN TOUCH THROUGH</p>
          <p id="contact-text-left">
            <a id="instagram-link" href="mailto:aidan.rahman@gmail.com"
              >AIDAN.RAHMAN@GMAIL.COM</a
            >
          </p>
          <p id="contact-text-right">
            <a
              id="instagram-link"
              href="https://www.instagram.com/aidan.rahman/"
              target="_blank"
              >@AIDAN.RAHMAN</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import albumData from "./albumSection.json";
import performanceData from "./performanceSection.json";
// import BackgroundPage from './BackgroundPage.vue'

export default {
  name: "PortfolioPage",
  components: {
    // BackgroundPage
  },
  data() {
    return {
      dynamicHeight: screen.height,
      windowHeight: screen.height,
      albumSection: albumData,
      performanceSection: performanceData,
      displayBackground: true,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // const scrollDistance = window.scrollY;
      // const changedMarginHeight = this.windowHeight - scrollDistance
      // if (changedMarginHeight >= 0) {
      //   this.dynamicHeight = changedMarginHeight
      // }
      console.log("scroll");
    },
    animateDiv(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;
        // Add a CSS class to apply the animation
        domElement.classList.add("element"); // You should define this CSS class
        // Remove the CSS class after the animation is complete (optional)
        domElement.addEventListener("animationend", () => {
          domElement.classList.remove("element");
        });
      }
    },
    handleClickLink(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    isSmallScreen() {
      return screen.width <= 450;
    },
  },
};
</script>

<style scoped>
/* Define the combined animation */
@keyframes slideInFadeIn {
  from {
    opacity: 0;
    /* Start with 0 opacity (fully transparent) */
    transform: translateY(-10px);
    /* Start with a slight slide from the left */
  }

  to {
    opacity: 1;
    /* End with 1 opacity (fully visible) */
    transform: translateY(0);
    /* End with no horizontal slide */
  }
}

/* Apply the combined animation to the element */
.element {
  animation: slideInFadeIn 1s forwards;
  /* 1s duration, forwards to keep the final state */
}

.gallery-section {
  padding: 1rem;
}

.gallery-section img {
  width: 100%;
  height: auto;
  /* Maintain the aspect ratio of the image */
}

.gallery-section h3 {
  text-decoration: underline;
  font-size: 0.8rem;
  text-align: left;
  margin-top: 1rem;
  text-transform: uppercase;
}

.gallery-section p {
  font-size: 0.8rem;
  text-align: left;
  text-transform: uppercase;
}

#portfolio-one-container {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}

#portfolio-two-container {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
}

#portfolio-one-row-container {
  padding: 1rem 1rem;
}

#portfolio-two-row-container {
  padding: 1rem 1rem;
}

#space-box {
  width: 100vw;
  /* background-color: red; */
}

#main-box {
  height: auto;
  width: 100vw;
  background-color: white;
  border-top: 2px solid #9aff76;
}

#background-text-box {
  font-size: 0.95rem;
  padding: 1rem 3rem;
  background-color: #9aff76;
}

#background-text-box p {
  margin: 0px !important;
}

#instagram-link {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 450px) {
  #header-text-left {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.8rem;
  }

  #header-text-right {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.8rem;
  }

  #contact-text-left {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.8rem;
  }

  #contact-text-right {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.8rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  #header-box {
    background-color: #9aff76;
    padding: 0.7rem 0rem;
  }

  #background-img {
    max-width: 65vw;
  }

  #background-text-box {
    font-size: 0.85rem;
    padding: 1rem 3rem;
  }
  #contact-box {
  }
}
</style>
