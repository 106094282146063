<template>
  <div id="app">
    <BackgroundPage class="background-page" />
    <div class="portfolio-page" @scroll="handleScroll">
      <div :style="{ height: dynamicHeight + 'px' }">
        <BackgroundPage ref="bg" class="dummy" v-if="dummyVisible" />
      </div>
      <PortfolioPage class="scrollable-content" />
    </div>
  </div>
</template>

<script>
import BackgroundPage from "./components/BackgroundPage.vue";
import PortfolioPage from "./components/PortfolioPage.vue";

export default {
  name: "App",
  components: {
    BackgroundPage,
    PortfolioPage,
  },
  data() {
    return {
      dynamicHeight: screen.height,
      maxHeight: screen.height,
      dummyVisible: true,
    };
  },
  methods: {
    handleScroll(event) {
      // console.log(event.target.scrollTop)
      if (this.dummyVisible) {
        this.dummyVisible = false;
        // const element = document.querySelector('.scrollable-content');
        // element.style.pointerEvents = 'none';
      }
      // console.log(event.target.scrollTop)
      if (event.target.scrollTop == 0) {
        this.dummyVisible = true;
        // const element = document.querySelector('.scrollable-content');
        // element.style.pointerEvents = 'none';
      }

      if (event.target.scrollTop == this.maxHeight) {
        // const element = document.querySelector('.scrollable-content');
        // element.style.pointerEvents = 'all';
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const element = document.querySelector(".background-page");
    element.style.height = screen.height;
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: "Space Grotesk", sans-serif;
  position: relative;
  height: 100dvh !important;
  min-height: -webkit-fill-available;
}
body {
  margin: 0px !important;
}
.background-page {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Take up the entire height of A */
  pointer-events: all;
  overflow: hidden;
}
.portfolio-page {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Set the height of C to 2X */
  overflow: auto; /* Enable scrolling for C */
  overflow-x: hidden;
  pointer-events: auto;
}
.background-page-front {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Take up the entire height of A */
}

/* .scrollable-content {
  height: 100%;
  overflow-x: hidden;
  pointer-events: none;
} */

/* CSS to apply easing effect */
html {
  scroll-behavior: smooth;

  /* Custom cubic-bezier easing */
  scroll-behavior: cubic-bezier(0.42, 0, 0.58, 1);
  overflow: hidden;
}

img[loading="lazy"]::before {
  src: "@/assets/lazyload.gif";
}

img[loading="lazy"] {
  src: "@/assets/lazyload.gif";
}
</style>
