import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { ObserveVisibility } from "vue-observe-visibility";

Vue.config.productionTip = false
Vue.directive("observe-visibility", ObserveVisibility);

new Vue({
  render: h => h(App),
}).$mount('#app')
