<template>
  <div
    id="background-box"
    class="d-flex flex-column justify-content-between"
    v-observe-visibility="animateDiv"
    @click="handleClick"
  >
    <!-- <div id="background-box" class="d-flex flex-column justify-content-between"> -->
    <div
      v-if="!isSmallScreen"
      id="header-box"
      class="d-flex justify-content-between align-items-center"
    >
      <p id="header-text-left">AIDAN RAHMAN</p>
      <p id="header-text-right">
        KEYBOARDIST/VIOLINIST/MUSIC DIRECTOR/PRODUCER
      </p>
    </div>

    <div v-if="isSmallScreen" id="header-box">
      <p id="header-text-left">AIDAN RAHMAN</p>
      <p id="header-text-right">
        KEYBOARDIST/VIOLINIST/MUSIC DIRECTOR/PRODUCER
      </p>
    </div>

    <img
      id="background-img"
      class="align-self-center"
      src="../assets/background.png"
      loading="lazy"
    />

    <div id="background-text-box">
      <p id="description">
        AIDAN IS A VERSATILE AUSTRALIA/LA BASED SESSION PLAYER, MUSIC DIRECTOR &
        PRODUCER KNOWN FOR HIS PROFICIENCY ON THE KEYS & VIOLIN. HIS PORTFOLIO
        INCLUDES STUDIO WORK & LIVE COLLABORATIONS WITH ACCLAIMED ARTISTS &
        MUSICIANS. AIDAN ALSO PLAYS A ROLE OF LEADING MUSIC DIRECTION FOR LIVE
        SHOWS/TOURS/PERFORMANCES, SHOWCASED ON RENOWNED STAGES & MUSIC FESTIVALS
        ACROSS AUSTRALIA.
      </p>
      <br /><br />

      <div
        v-if="!isSmallScreen"
        id="contact-box"
        class="d-flex justify-content-between align-items-center"
      >
        <p id="contact-text-left">GET IN TOUCH THROUGH</p>
        <p id="contact-text-left">
          <a id="instagram-link" href="mailto:aidan.rahman@gmail.com"
            >AIDAN.RAHMAN@GMAIL.COM</a
          >
        </p>
        <p id="contact-text-right">
          <a
            id="instagram-link"
            href="https://www.instagram.com/aidan.rahman/"
            target="_blank"
            >@AIDAN.RAHMAN</a
          >
        </p>
      </div>
      <div v-if="isSmallScreen" id="contact-box">
        <p id="contact-text-left">GET IN TOUCH THROUGH</p>
        <p id="contact-text-left">
          <a id="instagram-link" href="mailto:aidan.rahman@gmail.com"
            >AIDAN.RAHMAN@GMAIL.COM</a
          >
        </p>
        <p id="contact-text-right">
          <a
            id="instagram-link"
            href="https://www.instagram.com/aidan.rahman/"
            target="_blank"
            >@AIDAN.RAHMAN</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundPage",
  computed: {
    isSmallScreen() {
      return screen.width <= 450;
    },
  },
  methods: {
    animateDiv(isVisible, entry) {
      if (isVisible) {
        // trigger animation for the element
        let domElement = entry.target;

        // Add a CSS class to apply the animations
        domElement.classList.add("element"); // You should define this CSS class

        // Remove the CSS class after the animation is complete (optional)
        domElement.addEventListener("animationend", () => {
          domElement.classList.remove("element");
        });
      }
    },
    handleClick() {
      console.log("test");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#instagram-link {
  color: inherit;
  text-decoration: none;
}
#background-box {
  height: 100dvh;
  background-color: white;
}
#background-img {
  max-width: 40vw;
}
#header-box {
  background-color: #9aff76;
}
#header-text-left {
  margin: 0px !important;
  padding: 0.6vw;
  padding-left: 1vw;
  font-size: 1rem;
}
#header-text-right {
  margin: 0px !important;
  padding: 0.6vw;
  padding-right: 1vw;
  font-size: 1rem;
}
#background-text-box {
  font-size: 0.95rem;
  padding: 1rem 3rem;
}

/* Define the combined animation */
@keyframes slideInFadeIn {
  from {
    opacity: 0; /* Start with 0 opacity (fully transparent) */
  }
  to {
    opacity: 1; /* End with 1 opacity (fully visible) */
  }
}

/* Apply the combined animation to the element */
.element {
  animation: slideInFadeIn 1s forwards; /* 1s duration, forwards to keep the final state */
}

img[loading="lazy"]::before {
  src: "@/assets/lazyload.gif";
}

img[loading="lazy"] {
  src: "@/assets/lazyload.gif";
}

@media screen and (max-width: 450px) {
  #header-text-left {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.9rem;
  }
  #header-text-right {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.9rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #contact-text-left {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.8rem;
  }
  #contact-text-right {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.8rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #header-box {
    background-color: #9aff76;
    padding: 0.7rem 0rem;
  }
  #background-img {
    max-width: 65vw;
  }
  #background-text-box {
    font-size: 0.85rem;
    padding: 1rem 3rem;
  }
  #contact-box {
  }
  .contact-space {
    height: 3rem;
  }
}
</style>
